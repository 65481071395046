import React, {useState, forwardRef, useRef} from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/SelectV2"
import { Button } from "components/Buttonv2"
import { Search, SearchIcon, XIcon } from "lucide-react"
import { cn } from "util/cn"

export function DataTableToolbar({
    table,
    title = "",
    filterableColumns = [],
    searchableColumns = [],
    generateInvBtn = null,
    addUserButton = null,
    addCustomUserButton = null,
    newRowLink,
    search = false,
    deleteRowsAction,
}) {
    // const isFiltered = table.getState().columnFilters.length > 0

    const { search_field, search_text } = useParams()
    const [selectedSearchColumn, setSelectedSearchColumn] = useState(search_field || "")
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams(location.search)
    const [searchValue, setSearchValue] = useState(search_text || "")
    const searchFieldRef = useRef();

    return (
        <div className="flex w-full items-center justify-end space-x-2 p-3">
            <div className="flex flex-row items-center flex-wrap justify-between w-full">
                <h2 className="text-2xl font-bold mb-0 py-1.5 px-2" id="table_info">
                    {title}
                </h2>
                {/* {
                    generateInvBtn && (
                        <div className="flex justify-between flex-row items-center gap-2">
                            {generateInvBtn}
                        </div>
                    )
                } */}
                <div className="flex flex-row items-center gap-2">
                    {
                        generateInvBtn && (
                            <div className="flex justify-between flex-row items-center gap-2">
                                {generateInvBtn}
                            </div>
                        )
                    }
                {
                  search && searchableColumns.length > 0 && (
                        <div className="flex justify-between flex-row items-center gap-2">
                            <span id="search-description" className="sr-only">
                                {(searchValue && selectedSearchColumn) && 
                                <>
                                    Search for {searchValue} in {selectedSearchColumn}
                                </>}                                
                            </span>
                            <label htmlFor="searchBy" id="search-order-type">Search By : </label>
                            <Select
                                id="searchBy"
                                value={selectedSearchColumn}
                                onValueChange={(value) => {
                                    setSelectedSearchColumn(value)
                                }}
                                aria-label={`Search by ${selectedSearchColumn}`}
                            >
                                <SelectTrigger className="h-10 flex-1" aria-labelledby="search-order-type" >
                                    <SelectValue placeholder="Search by"/>
                                </SelectTrigger>
                                <SelectContent>
                                    {searchableColumns.map((column) => (
                                        <SelectItem key={column.id} value={column.id} className="focus:border border-black">
                                            {column.title}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <SearchInput
                                type="text"
                                placeholder="Search..."
                                className="w-auto"
                                disabled={selectedSearchColumn === ""}
                                value={searchValue}
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setSearchParams({})
                                    }
                                    setSearchValue(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setSearchParams({
                                            ...searchParams,
                                            page: 1,
                                            per_page: 10,
                                            search_field: selectedSearchColumn,
                                            search_text: searchValue,
                                        })
                                    }
                                }}
                                aria-label={`Search for ${searchValue} in ${selectedSearchColumn}`}
                                aria-describedby="search-description"
                                ref={searchFieldRef}
                                searchfieldref={searchFieldRef}
                            />
                        </div>
                    )
                }
                {
                    addUserButton && (
                        <div className="flex justify-between flex-row items-center gap-2">
                            {addUserButton}
                        </div>
                    )
                    
                }
                {
                    addCustomUserButton && (
                        <div className="flex justify-between flex-row items-center gap-2">
                            {addCustomUserButton}
                        </div>
                    )
                }
                </div>
            </div>
        </div>
    )
}


const SearchInput = forwardRef(
    ({ className, ...props }, ref) => {
        return (
            <div
                className={cn(
                    "flex h-10 min-w-[8rem] shadow-sm items-center rounded-md border border-input bg-white pl-3 text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2",
                    className,
                )}
            >
                <SearchIcon className="h-6 w-6" />
                <input
                    {...props}
                    type="text"
                    aria-label="Search"
                    ref={ref}
                    className="w-full p-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                />
                <div className="pr-1">
                    {
                        props.value ? (
                            <button
                                aria-label="Clear Search"
                                variant="icon"
                                className="h-6 w-6 p-1 rounded-full hover:bg-gray-200"
                                onClick={() => {
                                    props.onChange({ target: { value: "" } })
                                    props.searchfieldref.current.focus()
                                }}
                            >
                                <XIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                        ) : <div className="h-6 w-6 p-1"></div>
                    }
                </div>

            </div>
        );
    },
);

Search.displayName = "Search";

export { Search };